body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;


}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a:link {
  color: #c96d4f;
  background-color: transparent;
  text-decoration: none;
}

a:visited {
  color: #c96d4f;
  background-color: transparent;
  text-decoration: none;
}

a:hover {
  color: #913a1d;
  background-color: transparent;
  text-decoration: underline;
}

a:active {
  color: #f0e5e1;
  background-color: transparent;
  text-decoration: underline;
}


ul {
  list-style-type: none; /* Remove bullets */

}



#grid-pattern {
  background-color: #10151a;
  opacity: 0.8;
  background-image:  linear-gradient(#192129 2px, transparent 2px), 
  linear-gradient(90deg, #192129 2px, transparent 2px),
  linear-gradient(#192129 1px, transparent 1px), 
  linear-gradient(90deg, #192129 1px, #10151a 1px);
  background-size: 50px 50px, 50px 50px, 10px 10px, 10px 10px;
  background-position: -2px -2px, -2px -2px, -1px -1px, -1px -1px;
}